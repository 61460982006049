var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("b-card", [
        _c("label", [_vm._v("Current Phone Name:")]),
        _c(
          "div",
          [
            _c("b-input", {
              model: {
                value: _vm.phoneName,
                callback: function ($$v) {
                  _vm.phoneName = $$v
                },
                expression: "phoneName",
              },
            }),
          ],
          1
        ),
      ]),
      _c(
        "b-card",
        { staticClass: "mt-2" },
        [
          _c(
            "b-form-checkbox",
            {
              attrs: {
                checked: _vm.forwardType === "number-only",
                name: "forward-type",
              },
              on: { change: _vm.toggleCallForwarding },
            },
            [_vm._v("\n      Enable Call Forwarding\n    ")]
          ),
          _vm.forwardType === "number-only"
            ? _c(
                "div",
                { staticClass: "pt-2" },
                [
                  _c(
                    "span",
                    { staticClass: "pb-2", attrs: { id: "call-forward-text" } },
                    [
                      _vm._v(
                        "\n        We'll route your inbound calls to this phone number:\n      "
                      ),
                    ]
                  ),
                  _c("fa-icon", {
                    directives: [
                      {
                        name: "b-popover",
                        rawName: "v-b-popover.hover.top",
                        value: _vm.toolTip,
                        expression: "toolTip",
                        modifiers: { hover: true, top: true },
                      },
                    ],
                    staticClass: "question-circle pl-1 mr-2",
                    attrs: { icon: "question-circle", size: "1x" },
                  }),
                  _c(
                    "div",
                    { staticClass: "mt-2" },
                    [
                      _c("vue-phone-number-input", {
                        staticClass: "form-control",
                        attrs: {
                          "only-countries": ["US"],
                          "default-country-code": "US",
                          required: true,
                          clearable: true,
                        },
                        on: { update: _vm.validateNumberOnEvent },
                        model: {
                          value: _vm.forwardTo,
                          callback: function ($$v) {
                            _vm.forwardTo = $$v
                          },
                          expression: "forwardTo",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "b-card",
        { staticClass: "mt-2" },
        [
          _c(
            "b-form-group",
            { attrs: { label: "In-browser ringtone (Click to preview):" } },
            [
              _c("b-form-radio-group", {
                attrs: {
                  id: "ringtone",
                  options: _vm.ringtoneOptions,
                  "aria-describedby": _vm.ringtone,
                  name: "radio-options-ringtone",
                  size: "md",
                  stacked: "",
                },
                on: { change: _vm.playRingtoneExample },
                model: {
                  value: _vm.ringtone,
                  callback: function ($$v) {
                    _vm.ringtone = $$v
                  },
                  expression: "ringtone",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-card",
        { staticClass: "mt-2" },
        [
          _c(
            "b-form-group",
            [
              _c("label", [_vm._v("Message attachment retention period: ")]),
              _c("fa-icon", {
                directives: [
                  {
                    name: "b-popover",
                    rawName: "v-b-popover.hover.top",
                    value: _vm.mediaToolTip,
                    expression: "mediaToolTip",
                    modifiers: { hover: true, top: true },
                  },
                ],
                staticClass: "question-circle pl-1 mr-2",
                attrs: { icon: "question-circle", size: "1x" },
              }),
              _c("b-form-radio-group", {
                attrs: {
                  id: "media-lifespan",
                  options: _vm.mediaLifespanOptions,
                  "aria-describedby": _vm.mediaLifespan,
                  name: "radio-options-media-duration",
                  size: "md",
                  stacked: "",
                },
                model: {
                  value: _vm.mediaLifespan,
                  callback: function ($$v) {
                    _vm.mediaLifespan = $$v
                  },
                  expression: "mediaLifespan",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-card",
        { staticClass: "mt-2" },
        [
          _vm._v("\n    Blocked Phone Numbers\n    "),
          _vm.removingBlockedNumber
            ? _c("ct-centered-spinner")
            : _c(
                "b-list-group",
                { staticClass: "mt-2 blocked-number-list" },
                [
                  _vm._l(_vm.blockedNumbers, function (number) {
                    return [
                      _c("b-list-group-item", { key: number.id }, [
                        _c(
                          "div",
                          { staticClass: "d-flex flex-row" },
                          [
                            _c("fa-icon", {
                              staticClass: "ml-2 mt-1 mr-2 ban-icon",
                              attrs: { icon: "ban" },
                              on: {
                                click: function ($event) {
                                  return _vm.removeIconClicked(number.id)
                                },
                              },
                            }),
                            _c("p", {
                              directives: [
                                {
                                  name: "phone-number",
                                  rawName: "v-phone-number",
                                  value: number.blocked_number,
                                  expression: "number.blocked_number",
                                },
                              ],
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]
                  }),
                ],
                2
              ),
        ],
        1
      ),
      _c(
        "b-button",
        {
          staticClass: "phone-name-button btn-primary button-settings",
          on: { click: _vm.saveSettings },
        },
        [_vm._v("\n    Save Settings\n  ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }