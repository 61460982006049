var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-form",
        [
          _c(
            "b-card",
            { staticClass: "mb-2" },
            [
              _c("label", [_vm._v("Caller ID")]),
              _c("fa-icon", {
                directives: [
                  {
                    name: "b-popover",
                    rawName: "v-b-popover.hover.top",
                    value: _vm.toolTip,
                    expression: "toolTip",
                    modifiers: { hover: true, top: true },
                  },
                ],
                staticClass: "question-circle pl-1 mr-2",
                attrs: { icon: "question-circle", size: "1x" },
              }),
              _c("b-form-input", {
                staticClass: "mb-2",
                attrs: {
                  id: "firstName",
                  placeholder: "First Name",
                  required: "",
                },
                model: {
                  value: _vm.displayedFirstName,
                  callback: function ($$v) {
                    _vm.displayedFirstName = $$v
                  },
                  expression: "displayedFirstName",
                },
              }),
              _c(
                "b-tooltip",
                {
                  ref: "tooltip",
                  attrs: {
                    disabled: !_vm.invalidLength,
                    target: "firstName",
                    placement: "top",
                    triggers: "hover focus",
                  },
                },
                [
                  _vm._v(
                    "\n        Warning! These two fields cannot add to more than 14 characters.\n      "
                  ),
                ]
              ),
              _c("b-form-input", {
                attrs: { placeholder: "Last Name", required: "" },
                model: {
                  value: _vm.displayedLastName,
                  callback: function ($$v) {
                    _vm.displayedLastName = $$v
                  },
                  expression: "displayedLastName",
                },
              }),
            ],
            1
          ),
          _c("div", [
            _c(
              "div",
              { staticClass: "address-checkbox" },
              [
                _c(
                  "b-form-checkbox",
                  {
                    staticClass: "mb-2 mt-3",
                    model: {
                      value: _vm.includeAddress,
                      callback: function ($$v) {
                        _vm.includeAddress = $$v
                      },
                      expression: "includeAddress",
                    },
                  },
                  [
                    _vm._v(
                      "\n          Make this address publicly available on directory assistance\n        "
                    ),
                  ]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "address-tooltip" },
              [
                _c("fa-icon", {
                  directives: [
                    {
                      name: "b-popover",
                      rawName: "v-b-popover.hover.top",
                      value: _vm.includeAddressTooltip,
                      expression: "includeAddressTooltip",
                      modifiers: { hover: true, top: true },
                    },
                  ],
                  staticClass: "question-circle pl-1 mr-2",
                  attrs: { icon: "question-circle", size: "1x" },
                }),
              ],
              1
            ),
          ]),
          _c(
            "b-card",
            [
              _c(
                "b-container",
                { staticClass: "address-spacing" },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { staticClass: "mb-0" },
                        [
                          _c(
                            "b-form-group",
                            {
                              staticClass: "mb-0",
                              attrs: { label: "Address" },
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  "data-cy": "line1",
                                  placeholder: "Address",
                                  required: "",
                                },
                                model: {
                                  value: _vm.line1,
                                  callback: function ($$v) {
                                    _vm.line1 = $$v
                                  },
                                  expression: "line1",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-form-group",
                            { attrs: { label: "City" } },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  "data-cy": "city",
                                  placeholder: "City",
                                  required: "",
                                },
                                model: {
                                  value: _vm.city,
                                  callback: function ($$v) {
                                    _vm.city = $$v
                                  },
                                  expression: "city",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-form-group",
                            { attrs: { label: "State" } },
                            [
                              _c("b-form-select", {
                                attrs: {
                                  required: "",
                                  "data-cy": "stateProvinceRegion",
                                  options: _vm.states,
                                },
                                model: {
                                  value: _vm.stateProvinceRegion,
                                  callback: function ($$v) {
                                    _vm.stateProvinceRegion = $$v
                                  },
                                  expression: "stateProvinceRegion",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-form-group",
                            { attrs: { label: "Zipcode" } },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  required: "",
                                  "data-cy": "zipPostalCode",
                                  placeholder: "Zip",
                                },
                                model: {
                                  value: _vm.zipPostalCode,
                                  callback: function ($$v) {
                                    _vm.zipPostalCode = $$v
                                  },
                                  expression: "zipPostalCode",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-button",
            {
              staticClass: "phone-name-button btn-primary button-settings mt-2",
              attrs: { disabled: _vm.invalidLength },
              on: { click: _vm.saveSettings },
            },
            [_vm._v("\n      Save Settings\n    ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }