<template>
  <div>
    <b-form>
      <b-card class="mb-2">
        <label>Caller ID</label>
        <fa-icon v-b-popover.hover.top="toolTip" class="question-circle pl-1 mr-2" icon="question-circle" size="1x" />
        <b-form-input
          id="firstName"
          v-model="displayedFirstName"
          placeholder="First Name"
          required
          class="mb-2"
        />
        <b-tooltip ref="tooltip" :disabled="!invalidLength" target="firstName" placement="top" :triggers="'hover focus'">
          Warning! These two fields cannot add to more than 14 characters.
        </b-tooltip>
        <b-form-input
          v-model="displayedLastName"
          placeholder="Last Name"
          required
        />
      </b-card>
      <div>
        <div class="address-checkbox">
          <b-form-checkbox v-model="includeAddress" class="mb-2 mt-3">
            Make this address publicly available on directory assistance
          </b-form-checkbox>
        </div>
        <div class="address-tooltip">
          <fa-icon v-b-popover.hover.top="includeAddressTooltip" class="question-circle pl-1 mr-2" icon="question-circle" size="1x" />
        </div>
      </div>
      <b-card>
        <b-container class="address-spacing">
          <b-row>
            <b-col class="mb-0">
              <b-form-group label="Address" class="mb-0">
                <b-form-input v-model="line1" data-cy="line1" placeholder="Address" required />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group label="City">
                <b-form-input v-model="city" data-cy="city" placeholder="City" required />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="State">
                <b-form-select
                  v-model="stateProvinceRegion"
                  required
                  data-cy="stateProvinceRegion"
                  :options="states"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Zipcode">
                <b-form-input
                  v-model="zipPostalCode"
                  required
                  data-cy="zipPostalCode"
                  placeholder="Zip"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-container>
      </b-card>
      <b-button
        class="phone-name-button btn-primary button-settings mt-2"
        :disabled="invalidLength"
        @click="saveSettings"
      >
        Save Settings
      </b-button>
    </b-form>
  </div>
</template>

<script>
import { usStates } from '@/common/modules/usStates'
import { mapActions, mapGetters } from 'vuex'
import { formatRemovingCountryCode } from '@/common/modules/formatters'
const parser = require('parse-address')

export default {
  name: 'VirtualPhoneCallerIdSettings',
  data(){
    return {
      includeAddress: false,
      zipPostalCode: '',
      stateProvinceRegion: '',
      line1: '',
      city: '',
      streetName: '',
      streetSuffix: '',
      houseNumber: '',
      displayedFirstName: '',
      displayedLastName: '',
    }
  },
  computed: {
    ...mapGetters('virtualPhones', ['selectedPhone', 'virtualPhoneCnamParams']),
    ...mapGetters('jurisdictions', [
      'jurisdictions',
    ]),

    states: function () {
      return [
        [{ value: null, text: 'Please select a state' }],
        ...usStates
          .map(et => [{ text: et.stateProvinceRegion, value: et.abbreviation }])
          .flat(),
      ].flat()
    },
    usStates: function() {
      return usStates
    },
    invalidLength(){
      return 14 < `${this.displayedFirstName}${this.displayedLastName}`.length
    },
  },
  watch: {
    invalidLength (newVal) {
      if(newVal){
        this.$refs.tooltip.$emit('open')
      }
      else{
        this.$refs.tooltip.$emit('close')
      }
    },
  },
  mounted() {
    this.parseCnamRecordsForSelectedPhone()
  },
  methods: {
    ...mapActions('virtualPhones', ['setCallerIdSettings']),
    formatRemovingCountryCode: formatRemovingCountryCode,

    toolTip(){
      return 'The firstname and lastname fields can also be set to the name of the business. ' +
        'The initial part of the business name in the firstname field and the second half in the lastname field. ' +
        'If there is no "second half" one can utilize Corp, LLC, Inc, etc.. i.e. Amazing LLC'
    },
    includeAddressTooltip(){
      return 'To process your Caller ID request, we’ll need a physical address. ' +
        'This address is for verification purposes only—it will not appear on your outbound Caller ID. ' +
        'If you check the box to the left, the address you provide will be publicly accessible through directory assistance. ' +
        'If you’d rather keep your address private, leave the box unchecked.'
    },
    parseAddress(){
      const address = this.line1 + ' '
        + this.city + ' '
        + this.stateProvinceRegion + ' '
        + this.zipPostalCode

      const parsedAddress = parser.parseAddress(address)
      if (parsedAddress !== null){
        this.houseNumber = parsedAddress.number
        if (parsedAddress.prefix){
          this.streetName = `${parsedAddress.prefix} ${parsedAddress.street}`
        } else {
          this.streetName = parsedAddress.street
        }
        this.streetSuffix = parsedAddress.type
      }
    },
    setParams(){
      this.parseAddress()

      return {
        list_address: this.includeAddress,
        number: formatRemovingCountryCode(this.selectedPhone.number),
        displayed_first_name: this.displayedFirstName,
        displayed_last_name: this.displayedLastName,
        house_number: this.houseNumber,
        street_name: this.streetName,
        street_suffix: this.streetSuffix,
        city: this.city,
        state_code: this.stateProvinceRegion,
        zip: this.zipPostalCode,
      }
    },
    parseCnamRecordsForSelectedPhone(){
      let params = this.virtualPhoneCnamParams !== null ? this.virtualPhoneCnamParams :
        this.$store.getters["virtualPhones/selectedPhone"].cnam_data

      if (Object.keys(params).length > 0 ){
        let cnam_data = params['dlda_tn_groups']['dlda_tn_group'][0]

        this.includeAddress = cnam_data.list_address || false
        this.zipPostalCode = cnam_data.address.zip || ''
        this.stateProvinceRegion = cnam_data.address.state_code || ''
        this.houseNumber = cnam_data.address.house_number || ''
        this.streetName = cnam_data.address.street_name || ''
        this.city = cnam_data.address.city || ''
        this.streetSuffix = cnam_data.address.street_suffix || ''
        this.line1 = `${this.houseNumber} ${this.streetName} ${this.streetSuffix}`
        this.displayedFirstName = cnam_data.listing_name.first_name || ''
        this.displayedLastName = cnam_data.listing_name.last_name || ''
      }
    },
    invalidFields(){
      this.$bvToast.toast('Invalid Fields, Please make sure that the name fields and address fields are completed!', {
        title: 'Error',
        variant: 'danger',
        solid: true,
      })
    },
    saveSettings(){
      if (this.validName() && this.validAddress()){
        this.setCallerIdSettings({ callerIdSettings: this.setParams(), vm: this })
      } else {
        this.invalidFields()
      }
    },
    validName(){
      const validFirstName = this.displayedFirstName != null && this.displayedFirstName.length > 0
      const validLastName = this.displayedLastName != null && this.displayedLastName.length > 0

      if (!validLastName || !validFirstName){
        return false
      } else {
        return true
      }
    },
    validAddress (){
      const validLine1 = this.line1 != null && this.line1.length > 0

      const validCity = this.city != null && this.city.length > 0
      const validStateProvinceRegion =
        this.stateProvinceRegion != null && this.stateProvinceRegion.length > 0
      const validZipPostalCode =
        this.zipPostalCode != null && this.zipPostalCode.length > 0

      const validItems = (
        validLine1 &&
        validCity &&
        validStateProvinceRegion &&
        validZipPostalCode
      )
      return validItems
    },
  },
}
</script>

<style lang="scss" scoped>

.question-circle {
  color: $blue;
}

.address-tooltip {
  display: inline-block;
}

.address-checkbox {
  display: inline-block;
}

.address-spacing{
  padding: 0;
  margin-bottom: .25px;
  margin-top: .25px;
}
</style>
