var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "virtual-phone-voicemail-recorder-modal",
      attrs: { size: "lg", title: "Record Your Custom Voicemail Greeting" },
      scopedSlots: _vm._u([
        {
          key: "modal-footer",
          fn: function ({ ok, cancel }) {
            return [
              _c(
                "b-button",
                {
                  on: {
                    click: function ($event) {
                      return cancel()
                    },
                  },
                },
                [_vm._v("\n      Close\n    ")]
              ),
            ]
          },
        },
      ]),
    },
    [
      _c("div", [
        _c(
          "div",
          [
            _c("label", [_vm._v("Record New Greeting")]),
            _c("br"),
            _c(
              "b-button",
              {
                class: { red: _vm.recordingInProgress },
                on: { click: _vm.onButtonPress },
              },
              [_vm._v("\n        " + _vm._s(_vm.displayText) + "\n      ")]
            ),
          ],
          1
        ),
        _c("br"),
        _vm.recordList
          ? _c(
              "div",
              [
                _c("label", [_vm._v("Saved Greeting")]),
                _c("c-t-audio-controls", {
                  attrs: { "audio-source": _vm.preferredAudioSource },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }