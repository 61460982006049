<template>
  <b-container>
    <virtual-phone-masthead
      title="Settings for"
      @loaded="loadedPhoneMasthead"
      @change="resetAfterChange"
    />

    <b-tabs v-if="loaded" card content-class="mt-3">
      <b-tab title="Phone Settings" active>
        <virtual-phone-phone-settings :key="selectedPhone.id" />
      </b-tab>
      <b-tab title="Voicemail Settings">
        <virtual-phone-custom-voicemail-settings :key="selectedPhone.id" />
      </b-tab>
      <b-tab title="Caller ID Settings">
        <virtual-phone-caller-id-settings :key="selectedPhone.id" />
      </b-tab>
      <b-tab title="Mobile App Access">
        <mobile-access />
      </b-tab>
    </b-tabs>
    <div v-if="!arePhonesActive">
      <p>There are currently no active phones.</p>
    </div>
  </b-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import VirtualPhoneMasthead from '@/components/VirtualPhones/VirtualPhoneMasthead'
import VirtualPhoneCustomVoicemailSettings from "@/components/VirtualPhones/VirtualPhoneCustomVoicemailSettings"
import VirtualPhonePhoneSettings from "@/components/VirtualPhones/VirtualPhonePhoneSettings"
import VirtualPhoneCallerIdSettings from "@/components/VirtualPhones/VirtualPhoneCallerIdSettings"
import MobileAccess from "@/components/shared/MobileAccess"

export default {
  name: "VirtualPhoneSettings",
  components: {
    MobileAccess,
    VirtualPhonePhoneSettings,
    VirtualPhoneMasthead,
    VirtualPhoneCustomVoicemailSettings,
    VirtualPhoneCallerIdSettings,
  },

  data() {
    return {
      loaded: false,
    }
  },

  async mounted() {
    while(this.selectedPhone == null) {
      await new Promise( r => setTimeout(r, 200) )
    }
  },

  computed: {
    ...mapGetters('virtualPhones', ['selectedPhone']),
    arePhonesActive (){
      return this.selectedPhone
    },
  },

  methods: {
    ...mapActions('virtualPhones', ['setCustomVoicemailSettings', 'loadVirtualPhones', 'getBlockedNumbers']),

    async loadedPhoneMasthead(){
      await this.loadVirtualPhones()
      await this.setCustomVoicemailSettings()
      await this.getBlockedNumbers()
      this.loaded = true
    },
    async resetAfterChange(){
      this.loaded = false
      await this.loadVirtualPhones()
      await this.setCustomVoicemailSettings()
      await this.getBlockedNumbers()
      this.loaded = true
    },
  },

}
</script>
